@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  /* overflow-x: hidden; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-image {
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;

  text-align: center;
  color: white;
}
.bg-softwaredevelopment-image {
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  text-align: center;
  color: white;
}

@media screen and (max-width: 767px) {
  .fussion_fullwidth {
    background: #fff url("./assets/gif/mobile_development_iPhone.gif") fixed 50%
      270px no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 320px;
    background-size: 35%;
  }
  .fussion_fullwidth1 {
    background: #14446d url("./assets/gif/mobile_development_samsung.gif") fixed
      50% 270px no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #14446d;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 320px;
    background-size: 35%;
  }
  .fussion_fullwidth2 {
    background: #fc6c14 url("./assets/gif/mobile_development_pixel.gif") fixed
      50% 270px no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #fc6c14;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 320px;
    background-size: 35%;
  }
}
.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}
/* Global styles */
.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
.quotesform::placeholder {
  color: white !important;
}
@media screen and (min-width: 767px) {
  .fussion_fullwidth {
    background: #fff url("./assets/gif/mobile_development_iPhone.gif") fixed 87%
      170px no-repeat;
    /* background-position: right; */
    padding-top: 100px;
    padding-right: 30px;
    padding-bottom: 80px;
    padding-left: 30px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 800px;
    background-size: 15%;
  }
  .fussion_fullwidth1 {
    background: #14446d url("./assets/gif/mobile_development_samsung.gif") fixed
      87% 170px no-repeat;
    padding-top: 100px;
    padding-right: 30px;
    padding-bottom: 80px;
    padding-left: 30px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #14446d;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 800px;
    background-size: 15%;
  }
  .fussion_fullwidth2 {
    background: #fc6c14 url("./assets/gif/mobile_development_pixel.gif") fixed
      87% 170px no-repeat;
    padding-top: 100px;
    padding-right: 30px;
    padding-bottom: 80px;
    padding-left: 30px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: #fc6c14;
    border-top-style: solid;
    border-bottom-style: solid;
    min-height: 800px;
    background-size: 15%;
  }
}
body[data-scroll-step="44"] .et_pb_image_0 {
  transform: translateX(0px) scale3d(0.7, 0.7, 0.7) rotate3d(0, 0, 1, 90deg);
  transition: transform 200ms linear;
}
.scroll {
  overflow: auto;
}
.scroll::-webkit-scrollbar {
  border-width: 0.1px;
}

.fade-transition {
  transition: opacity 0.5s ease-in-out;
}
/* Define a custom CSS class for the hover effect */
.custom-card:hover {
  background-position: right;
  color: white;
}

/* Define a class for the container div */
.custom-card {
  background: gray; /* default color */
  background: linear-gradient(to right, #ebebeb 50%, #14446d 50%) left;
  background-size: 200%;
  transition: 0.4s ease-out;
}

/* Define a class for the image container */
.custom-card .image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  width: 20vw; /* Adjust the width as needed */
  height: 10vh; /* Adjust the height as needed */
}

/* Define a class for the card content */
.custom-card .card-content {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0.5rem;
  transition: background-color 0.3s ease-in-out;
}

/* Define a class for the card title */
.custom-card .card-content .card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

/* Define a class for the card description */
.custom-card .card-content .card-description {
  font-size: 14px;
  line-height: 1.5;
}

.placeholder-option {
  color: #a0aec0;
}

.font-bold, .h1, .h2, .h3, .h5, .h6 {
  line-height: 1 !important;
}