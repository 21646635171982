.App {
  /* text-align: center; */
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dropdown-menu {
  /* display: none; */
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(5, minmax(auto, 1fr));
  gap: 20px;
  padding: 30px 0px 20px 100px !important;
  min-height: 100%;
  justify-content: center;
  /* align-items: center; */
  margin: auto !important;
  overflow: hidden;
  top: 100%;
  left: 0;
  /* z-index: 999; */
  /* background-color: #ffffff; */
  /* border: 1px solid #e5e7eb; */
  border-radius: 4px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
  padding: 10px;
  width: auto;
}

li a:hover {
  color: #FEA315 !important;
  transition: transform 0.3s ease;
  font-weight: 500;
}

li:hover .dropdown-menu {
  color: #FEA315 !important;
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 20px;
  padding: 20px 30px;
  min-height: 100%;
  justify-content: center;
  /* align-items: center; */
  margin: auto;
  overflow: hidden;
  /* overflow-y: auto; */
  /* margin-top: 5px; */
}
.dropdown-icon {
  transition: transform 0.3s ease;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

li:hover .dropdown-icon {
  transform: rotate(180deg);
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the value as needed */
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}
